import { EasyGridLayout, LineLayout } from '@/layout'
import React, { useState } from 'react'
import { useRequest } from 'ahooks'
import { Equipment } from '@/api/request'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash'
import { getTimeDiff } from '@/utils/time'
import moment from 'moment'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import './index.scss'
import { useHistory } from 'react-router'
import { store } from '@/store'
import classNames from 'classnames'
interface Props {
    data?: Object | Array<any>
}

let content = <Loading />
const Oven = (props: Props) => {
    const history = useHistory()
    const lineCode = useState(searchParams().lineCode)[0]
    let { data, loading, error } = useRequest(() => {
        return Equipment.getOvensCurrentParameters(lineCode)
    }, { pollingInterval: 10 * 1000 })
    if (loading) return content
    if (error) return <Error />
    const ovenOrRefrigerators = store.getState().ovenOrRefrigerators
    const ovenWithTemperature = ovenOrRefrigerators.map((item: any) => {
        let oven = data.find((oven: any) => { return item.code === oven.ovenCode })
        return {
            ...item,
            temperature: oven ? oven.temperatureInC : null,
            power: oven ? oven.powerInW : null,
            acquiredTime: oven ? oven.acquiredTime : null
        }
    })

    const ovensColums = Math.ceil(ovenWithTemperature.length / 2)
    const ovenContainerWidth = ovensColums * 120
    const mergeRobotsAndCameras = () => {
        const robots = store.getState().robots
        const cameras = store.getState().cameras
        let result = []
        cameras.forEach((item: any) => {
            const entry = robots.find((e: any, idx: number) => e.workStationCode === item.workStationCode)
            if (!entry) {
                result.push(item)
            }
        })
        return [...robots, ...result]
    }

    const projectShortCode = store.getState().projectInfo.shortCode
    const onClickJump = (e: any, item: any) => {
        e.stopPropagation()
        //如果
        if (item.workStationCode) {
            history.push(`/line/workStation?lineCode=${item.lineCode}&workStationCode=${item.workStationCode}`)
        } else {
            history.push(`/line/workStation?lineCode=${item.lineCode}&cameraId=${item.id}`)
        }
    }


    const getOvenNameColor = (name: string) => {
        if (name === '热定型') return 'red'
        if (name === '冷定型') return '#00e2ff'
        return 'orange'
    }

    const getOvenPower = (acquiredTime: string, power: any) => {
        if (power === null) return '--'
        if (acquiredTime === null) return '--'
        if (getTimeDiff(acquiredTime, moment().format()) >= 300) {
            return '--'
        }
        return _.round(power) + ' kW'


    }
    content = <div className='oven-content'>

        <LineLayout height={280} justify='between' padding={[20, 80, 0, 80]} >
            <EasyGridLayout columns={ovensColums} rows={2} width={ovenContainerWidth} height={230} gap={10} margin={[20, 0, 0, 0]}>
                {
                    ovenWithTemperature.map((item: any) => {
                        return <div className='oven-status'>
                            <DigitalFlop
                                size={0.65}
                                title={item.name}
                                count={item.temperature || null}
                                suffix='℃'
                                type='count'
                                color={getOvenNameColor(item.name)} />
                            <p className='power'>{getOvenPower(item.acquiredTime, item.power)}</p>
                        </div>
                    })
                }
            </EasyGridLayout>

            <div className={`line-pic ${projectShortCode}`} />

        </LineLayout>


        <LineLayout justify='start' height={80} gap={10} margin={[20, 0, 10, 70]} padding={[0, 10, 0, 0]}>
            {
                mergeRobotsAndCameras().map((item: any) => {
                    return <div className='link-button' onClick={(e) => { onClickJump(e, item) }}>{item.name}</div>
                })
            }
            <div className='link-button' onClick={(e) => { e.stopPropagation(); history.push(`/iot?lineCode=${lineCode}`) }}>{'物联网看板'}</div>
        </LineLayout>
    </div >
    return content
}
export { Oven }
